import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import Title from '../components/page-title'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Copy from '../components/page-copy'

import { theme, responsiveFont, fontRegular } from '../util/style'
import Image from '../components/page-image-new'

const Image1 = 'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-001.jpg'
const FloorplanImage1 = 'for-sale/floorplan-tarragindi-2.png'
const FloorplanImage2 = 'for-sale/floorplan-tarragindi-2-1.png'

const DesignImage1 =
  'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-002.jpg'
const DesignImage2 =
  'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-004.jpg'
const DesignImage3 =
  'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-003.jpg'
const DesignImage4 =
  'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-005.jpg'
const DesignImage5 =
  'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-006.jpg'
const DesignImage6 =
  'for-sale/happyhaus-gallery-2023-02-27-render-tarragindi-007.jpg'

const SplitImage = ({ image, label }) => {
  return (
    <SplitImageWrapper mb={[3]}>
      {label ? (
        <SplitImageTextWrapper pr={3} mt={[3, 0]}>
          <Copy>{label}</Copy>
        </SplitImageTextWrapper>
      ) : (
        <Box />
      )}
      <Image src={image} />
    </SplitImageWrapper>
  )
}

const HausTarragindi = () => {
  return (
    <>
      <SEO title="Haus Tarragindi" />

      <HeaderWrapper my={[3, 4]}>
        <Title noPaddingTop noMargin>
          For Sale
        </Title>
        <Link href="/contact">
          <PillButton ml={2} as={'button'}>
            Enquire Now
          </PillButton>
        </Link>
      </HeaderWrapper>
      <Wrapper>
        <TitleRow pb={[2, 3]}>
          <LogoWrapper>
            <Link href="/">
              <svg
                width="86"
                height="53"
                viewBox="0 0 86 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M86 0V53H0V22.9461L86 0Z" fill="#303030" />
              </svg>
            </Link>
          </LogoWrapper>
          <TitleWrapper>
            <Title noMargin>Haus Tarragindi - SOLD</Title>
          </TitleWrapper>
        </TitleRow>
        <CopyRow py={[2, 3]}>
          <Box>
            <Box mb={2}>
              <Info>Design + Build</Info>
              <Info style={{ color: '#888888' }}>Happy Haus</Info>
            </Box>
            <Box mb={2}>
              <Info>Land + Development</Info>
              <Info style={{ color: '#888888' }}>Happy Haus</Info>
            </Box>
          </Box>
          <Title noMargin color="#888888">
            Carefully curated with the perfect balance of design and nature,
            this modern abode will showcase a suite of premium inclusions,
            stunning landscaping and a seamless blend of materials.
          </Title>
        </CopyRow>
        <ImageRow mb={[3, 4]}>
          <Image src={Image1} />
        </ImageRow>
        <PlanRow pt={[1, 2]} mb={[3, 4]}>
          <Title>Plan</Title>
          <SplitImage label="Ground" image={FloorplanImage1} />
          <SplitImage label="Upper" image={FloorplanImage2} />
        </PlanRow>
        <DesignRow pt={[1, 2]} mb={[3, 4]}>
          <Title>Design</Title>
          <SplitImage
            label="Clean lines and curved accents create softness and elegance, while crisp white and earthy tones embody a homely ambience that ties in with the leafy surroundings."
            image={DesignImage1}
          />

          <SplitImage label="" image={DesignImage2} />
          <SplitImage
            label="An expansive and interconnected lounge and dining area draws the eye and reveals built-in cabinetry and a glass display wine cellar."
            image={DesignImage3}
          />
          <SplitImage
            label="A soaring void and banks of glass sliders accentuate the natural light, cross-ventilation and greenery from the garden courtyard and backyard. "
            image={DesignImage4}
          />
          <SplitImage
            label="The exquisite main suite is home to a lavish walk-in robe and dressing room unveiling floor-to-ceiling tiles, arched mirror cabinets, a freestanding bath and relaxing rainfall shower."
            image={DesignImage5}
          />
          <SplitImage label="" image={DesignImage6} />
        </DesignRow>
        <ContactRow>
          <Box></Box>
          <Title noPaddingTop>
            For sales enquiries, please{' '}
            <Link target={'__blank'} href="/contact">
              contact us
            </Link>
          </Title>
        </ContactRow>
      </Wrapper>
    </>
  )
}

export default HausTarragindi

const Wrapper = styled(Box)``
const LogoWrapper = styled(Box)``
const SplitRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const CopyRow = styled(SplitRow)``
const TitleRow = styled(SplitRow)`
  border-bottom: 1px solid #c4c4c4;
`
const TitleWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
`
const ImageRow = styled(Box)``
const PlanRow = styled(Box)`
  border-top: 1px solid #c4c4c4;
`

const SplitImageWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 30% 70%;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`
const SplitImageTextWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
`

const DesignRow = styled(Box)`
  border-top: 1px solid #c4c4c4;
`

const ContactRow = styled(SplitImageWrapper)`
  a {
    color: #8888;
    text-decoration-color: #8888;
  }
`

const Info = styled(Box)``

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const PillButton = styled(Box)`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  padding: 12px 20px;
  cursor: pointer;
  color: #31333a !important;
`

const Notifbar = styled(Box)`
  background-color: #303030;
  color: white;
  text-align: center;
  ${fontRegular}

  a {
    color: white;
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const ContactText = styled(Box)`
  color: #888888;
`
